import * as t from "io-ts";
import {omit, validateApi} from "api-common";
import {AssignmentPreference, ModifyAssignmentPreferences} from "../entities/assignment-preferences";
import {Roles} from "../entities/user";


export const assignmentPreference = validateApi({
    getForFamily: {
        path: "/assignmentPreference/getForFamily",
        params: t.type({
            familyId: t.number
        }),
        queryParams: t.undefined,
        returns: ModifyAssignmentPreferences,
        roles: [Roles.ACTIVE, Roles.PLAN_GENERATOR]
    },
    updateById: {
        path: "/assignmentPreference/updateById",
        params: t.type({
            preferenceId: t.number,
            newPreferences: t.partial(omit(AssignmentPreference.props, "id"))
        }),
        queryParams: t.undefined,
        returns: t.type({
            success: t.boolean
        }),
        roles: [Roles.ACTIVE, Roles.PLAN_GENERATOR]
    },
    getPersonal: {
        path: "/assignmentPreference/getPersonal",
        params: t.undefined,
        queryParams: t.undefined,
        returns: ModifyAssignmentPreferences,
        roles: [Roles.ACTIVE]
    },
    updatePersonal: {
        path: "/assignmentPreference/updatePersonal",
        params: t.type({
            newPreferences: t.partial(omit(omit(AssignmentPreference.props, "id"), "familyId"))
        }),
        queryParams: t.undefined,
        returns: t.type({
            success: t.boolean
        }),
        roles: [Roles.ACTIVE]
    },
})
import * as t from "io-ts";
import {omit, tsDayOfWeek, tsUnion} from "api-common";
import {TypeOf} from "io-ts";
import {Family} from "./family";

export const AssignmentPreference = t.type({
    id: t.number,
    familyId: t.number,
    strictTwoWeeks: t.boolean,
    unreliable: t.boolean,
    friends: t.array(t.number),
    assign: t.boolean,
    daysToSchedule: tsUnion([t.null, t.array(tsDayOfWeek)]),
    scheduleWithoutPause: t.boolean,
})
export type AssignmentPreference = TypeOf<typeof AssignmentPreference>

export const ModifyAssignmentPreferences = t.type({
    ...omit(AssignmentPreference.props, "familyId"),
    family: Family,
    friends: t.array(Family),
    daysToSchedule: t.array(t.number)
})
export type ModifyAssignmentPreferences = TypeOf<typeof ModifyAssignmentPreferences>;
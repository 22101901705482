




























































































import Vue from 'vue';
import {Backend} from "api-frontend";
import {ModifyAssignmentPreferences} from "mp-common/src/types/entities/assignment-preferences";
import {assignmentPreference} from "mp-common/src/types/api/assignmentPreferenceApi";
import {Family, FamilyWithMembers} from "mp-common/src/types/entities/family";
import {familyApi} from "mp-common/src/types/api/familyApi";
import {hasRoles} from "mp-common/src/helper/permissions";
import {Roles, User} from "mp-common/src/types/entities/user";
import {DayOfWeek} from "js-joda";
import {formatDayOfWeek} from "@/helpers/format-date-time";

export default Vue.extend({
  name: 'ModifyAssignmentPreferences',
  props: {
    user: {} as () => User,
    backend: {
      type: Backend,
    }
  },
  data() {
    return {
      formData: undefined as undefined | ModifyAssignmentPreferences,
      families: [] as FamilyWithMembers[],
      customFamily: "",
      updating: false,
      showAddFamilyDialog: false,
      addFamilySelection: "",
    }
  },
  created() {
    this.fetchAssignments();
    this.fetchFamilies();
  },
  methods: {
    async fetchAssignments() {
      const family = this.families.find((family) => family.name === this.customFamily);
      if (family) {
        this.formData = await this.backend.query(assignmentPreference.getForFamily, {familyId: family.id})
      } else {
        this.formData = await this.backend.query(assignmentPreference.getPersonal, undefined);
      }
    },
    async fetchFamilies() {
      this.families = await this.backend.query(familyApi.getAll, undefined) ?? [];
    },
    async updateAssignments() {
      if (!this.formData) {
        return;
      }
      this.updating = true;
      const family = this.families.find((family) => family.name === this.customFamily);
      if (family) {
        const result = await this.backend.query(assignmentPreference.updateById, {
          preferenceId: this.formData.id,
          newPreferences: {
            friends: this.formData.friends.map((friend) => friend.id),
            daysToSchedule: this.formData.daysToSchedule.length > 0 ? this.formData.daysToSchedule.map((day) => DayOfWeek.of(day)) : null,
            strictTwoWeeks: this.formData.strictTwoWeeks,
            unreliable: this.formData.unreliable,
            assign: this.formData.assign,
            familyId: this.formData.family.id,
            scheduleWithoutPause: this.formData.scheduleWithoutPause,
          }
        })
        if (!result?.success) {
          await this.fetchAssignments();
        }
      } else {
        const result = await this.backend.query(assignmentPreference.updatePersonal, {
          newPreferences: {
            friends: this.formData.friends.map((friend) => friend.id),
            daysToSchedule: this.formData.daysToSchedule.length > 0 ? this.formData.daysToSchedule.map((day) => DayOfWeek.of(day)) : null,
            strictTwoWeeks: this.formData.strictTwoWeeks,
            unreliable: this.formData.unreliable,
            assign: this.formData.assign,
            scheduleWithoutPause: this.formData.scheduleWithoutPause,
          }
        });
        if (!result?.success) {
          await this.fetchAssignments();
        }
      }
      this.updating = false;
    },
    hasPlanCreatorRole() {
      return hasRoles(this.user, Roles.PLAN_GENERATOR);
    },
    getDays() {
      return [DayOfWeek.SATURDAY.value(), DayOfWeek.SUNDAY.value()]
    },
    formatDayOfWeek(day: number) {
      return formatDayOfWeek(DayOfWeek.of(day));
    },
    addFamilyToFriends() {
      if (this.formData) {
        const family = this.families.find((family) => family.name === this.addFamilySelection);
        if (family) {
          this.formData.friends.push({...family, members: family?.members.map(member => member.id)});
          this.showAddFamilyDialog = false;
          this.addFamilySelection = "";
        }
        this.updateAssignments();
      }
    },
    removeFriend(family: Family) {
      if (this.formData) {
        this.formData.friends = this.formData.friends.filter((friend) => friend.id !== family.id);
      }
      this.updateAssignments();
    },
    getNotFriendFamilies() {
      return this.families.filter((family) => !this.formData?.friends.find(friendFamily => friendFamily.id === family.id) && this.formData?.family.id !== family.id).sort((first, next) => first.name && next.name ? first.name.localeCompare(next.name) : 0)
    },
    getSortedFamilies() {
      return this.families.filter(() => true).sort((first, next) => first.name && next.name ? first.name.localeCompare(next.name) : 0)
    }
  }
})

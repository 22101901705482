import * as t from "io-ts";
import {Roles} from "../entities/user";
import {Family, FamilyWithMembers} from "../entities/family";
import {omit, validateApi} from "api-common";


export const familyApi = validateApi({
    getAll: {
        path: "/family/getAll",
        params: t.undefined,
        queryParams: t.undefined,
        returns: t.array(FamilyWithMembers),
        roles: [Roles.ACTIVE]
    },
    add: {
        path: "/family/add",
        params: t.type(omit(Family.props, "id")),
        queryParams: t.undefined,
        returns: t.type({
            success: t.boolean
        }),
        roles: [Roles.ACTIVE, Roles.PLAN_GENERATOR]
    },
    remove: {
        path: "/family/remove",
        params: t.type({
            familyId: t.number
        }),
        queryParams: t.undefined,
        returns: t.type({
            success: t.boolean
        }),
        roles: [Roles.ACTIVE, Roles.PLAN_GENERATOR]
    },
    alter: {
        path: "/family/alter",
        params: t.type({
            familyId: t.number,
            replacement: t.partial(omit(Family.props, "id"))
        }),
        queryParams: t.undefined,
        returns: t.type({
            success: t.boolean
        }),
        roles: [Roles.ACTIVE, Roles.PLAN_GENERATOR]
    },
})
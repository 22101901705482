import * as t from "io-ts";
import {TypeOf} from "io-ts";
import {User} from "./user";

export const Family = t.type({
    id: t.number,
    members: t.array(t.number),
    name: t.union([t.undefined, t.string])
})
export type Family = TypeOf<typeof Family>;

export const FamilyWithMembers = t.type({
    id: t.number,
    members: t.array(User),
    name: t.union([t.undefined, t.string])
})
export type FamilyWithMembers = TypeOf<typeof FamilyWithMembers>;